<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          ref="crud_table"
          :fields="[
            //'id',
            'name',
            { ...helpers.monthName, key: 'init_month', helper: { ...helpers.monthName.helper, id: 'init_month_id' } },
            { ...helpers.monthName, key: 'end_month', helper: { ...helpers.monthName.helper, id: 'end_month_id' } },
            helpers.currencyValue,
            'description',
            { ...helpers.activeBadge, key: 'active', helper: { ...helpers.activeBadge.helper, id: '_active' } } // TODO: really ?
          ]"
          :config="{
            url: 'admin/budgets',
            route: '/admin/property/budgets',
            name: 'budget',
            delete: false,
            parseItems
          }"
          @preparedHelpers="onPreparedHelpers"
        >
          <template #delete="{item}">
            <CButton v-if="item._init_month_id === latest && item.__deletable" color="danger" @click="_deleteItem(item)">{{ $t('Delete') }}</CButton>
          </template>
        </ACRUDTable>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'Budgets',
  mixins: [crudMixin],
  data() {
    return {
      latest: 0,
      deleted: 0
    }
  },
  methods: {
    parseItems(parent, items) {
      this.latest = 0
      return items.map(item => {
        item._init_month_id = item.init_month_id
        if (item._init_month_id !== this.deleted && item._init_month_id > this.latest) this.latest = item._init_month_id // TODO: Numeric  // TODO: Workaround deleted
        item.__deletable = true
        if (this.property._periods?.REC.month_id >= item._init_month_id) item.__deletable = false
        for (const period_closing of item.periods_closing || []) {
          if (period_closing.period_type_id === 'FAC' && period_closing.status === 'C') item.__deletable = false
        }
        return item
      })
    }
  }
}
</script>
