var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{ref:"crud_table",attrs:{"fields":[
          //'id',
          'name',
          Object.assign({}, _vm.helpers.monthName, {key: 'init_month', helper: Object.assign({}, _vm.helpers.monthName.helper, {id: 'init_month_id'})}),
          Object.assign({}, _vm.helpers.monthName, {key: 'end_month', helper: Object.assign({}, _vm.helpers.monthName.helper, {id: 'end_month_id'})}),
          _vm.helpers.currencyValue,
          'description',
          Object.assign({}, _vm.helpers.activeBadge, {key: 'active', helper: Object.assign({}, _vm.helpers.activeBadge.helper, {id: '_active'})}) // TODO: really ?
        ],"config":{
          url: 'admin/budgets',
          route: '/admin/property/budgets',
          name: 'budget',
          delete: false,
          parseItems: _vm.parseItems
        }},on:{"preparedHelpers":_vm.onPreparedHelpers},scopedSlots:_vm._u([{key:"delete",fn:function(ref){
        var item = ref.item;
return [(item._init_month_id === _vm.latest && item.__deletable)?_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){return _vm._deleteItem(item)}}},[_vm._v(_vm._s(_vm.$t('Delete')))]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }